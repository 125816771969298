
















































.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 290px;*/
  height: 64px;
  line-height: 64px;
  /*background: #002140;*/
  color: #fdfdfd;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  box-sizing: border-box;
}
.logo_title {
  /*padding: 10px 0 0 0;*/

  color: white;
  font-size: 30px;
  &.is-bold {
    font-weight: 700;
  }
}

.is-img {
  /*position: absolute;*/
  /*top: 10px;*/
  /*left: 13px;*/
  display:flex;
  align-items: center;
  justify-content: center;
}
.logo_subtitle {
  font-size: 16px;
  padding-top: 5px;
}

