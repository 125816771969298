













































  .itemList{
  height:74px;
  background:rgba(255,255,255,1);
  border-radius:2px;
  border-left:4px solid #E4618F;
  display:flex;
  padding:10px 0;
  box-sizing: border-box;
	margin-bottom: 10px;

  }
  .date{
  display:block;
  font-size:24px;
  font-weight:500;
  color:rgba(51,51,51,1);
  width:64px;
  text-align: center;
  }
  .icon{
  color: rgba(153,153,153,1);
  font-size: 9px;
  margin-right: 4px;
  }
  .left{
  border-right: 1px solid rgba(243,243,243,1);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .content{
  white-space: normal;
  font-size:10px;
  font-weight:400;
  color:rgba(153,153,153,1);
  line-height:14px;
  }
  .right{
  padding: 0 10px;
  flex-grow: 1;
  }
  .right>div{
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  }
  .h2{
  font-size:14px;
  font-weight:600;
  color:rgba(51,51,51,1);
  line-height:20px;
  }
  .time{
  font-size:10px;
  font-weight:400;
  color:rgba(153,153,153,1);
  line-height:14px;
  }
