




































































































.index {
  display: flex;
  position: relative;
  background: #fff;
  overflow: hidden;
  height: 100%;
  .left {
    width: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    background: #393F6C;
  }
  .left:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    z-index: 10;
  }
  .left.is-active:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 100%;
    background-color: #7fc4ff;
  }
  .right {
    /*padding-top: 60px;*/
    position: relative;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    flex: 1;
  }
  .main {
    height: calc(100vh - 62px);
    background: #F5F5F5;
    box-sizing: border-box;
    overflow-y: scroll;
    .router {
      padding: 15px;
      background: #fff;
      min-height: 100%;
      height: auto;
      overflow: hidden;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }
  .nav {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  }
  .top {
    /*margin-bottom: 2px;*/
    height: 60px;
  }
}

