





























.errorPage{
  height:calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorContent{
  position: relative;
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width: 1000px;
    margin-bottom: 30px;
  }
  .errorBth{
    .errorBack1{
      width: 180px;
      height: 40px;
      padding: 0;
      border:1px solid #399FFE;
      color:#399FFE;
      border-radius: 20px;
      background: none;
      font-size: 20px;
      font-weight: 100;
    }
    .errorBack2{
      width: 180px;
      height: 40px;
      padding: 0;
      border:1px solid #399FFE;
      border-radius: 20px;
      color:#ffffff;
      background: #399FFE;
      font-size: 20px;
      font-weight: 100;
    }
  }
}
