








































































































































































.invoiceTable{
  box-shadow:0px 1px 7px 0px rgba(0,0,0,0.1);
  border-radius: 5px;
  background: #fff;
  padding: 14px 20px;
  .vxe-table .vxe-body--column:not(.col--ellipsis),
  .vxe-table .vxe-footer--column:not(.col--ellipsis),
  .vxe-table .vxe-header--column:not(.col--ellipsis){
    padding: 0;
  }
  .vxe-table--filter-wrapper .vxe-default-input,
  .vxe-table--filter-wrapper .vxe-default-select,
  .vxe-table--filter-wrapper .vxe-default-textarea,
  .vxe-table .vxe-default-input,
  .vxe-table .vxe-default-select,
  .vxe-table .vxe-default-textarea{
    height: 26px;
    padding: 0 6px;
    color:#000;
    font-size: 12px;
  }
}
