

























































































.itemBox {
  padding: 0 10px;
}

.itemLists {
  margin-top: 20px;
}

.itemList {
  margin-bottom: 20px;
  padding: 0 20px;
  cursor: move;
}

.itemListBox {
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  padding: 0 20px;
  border-radius: 2px;
}

.itemList p {
  color: #666666;
  font-size: 12px;
}

.itemTitle p.drag {
  flex: 1;
  cursor: move;
}

.itemList p + p {
  color: #000000;
  font-size: 14px;
}

.itemTitle {
  background: #FFEDBE;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.itemTitle p {
  color: #000000;
  font-size: 14px;
  line-height: 30px;
}

.itemTitle p + p {
  color: #AA5401;
  font-size: 12px;
  cursor: pointer;
}

.itemTitle p .icon {
  margin-right: 5px;
}

