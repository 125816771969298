



























































































































































.el-menu-item{
  background: #1C2C52 !important;
}
.el-menu-item.is-active {
  color: #409EFF !important;
}
.el-menu-item:hover{
  background: #2C467E !important;
}
.el-menu-item{
  .icon{
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
    color: #ffffff;
  }
}
.el-submenu{
  .icon{
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }
}
