


























































































































































































































































  .out-menu{
    width: 200px;
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y:auto;
  }
  .inner-menu{
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
  }

  /* for Chrome */
  .inner-menu::-webkit-scrollbar {
    display: none;
  }

  #app .el-menu{
    background: #393F6C;
  }
  .home_shousuo{
    text-align: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    line-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
  }

/*.sidebar-container.is-active{*/
/*  width: 80px !important;*/
/*}*/
