





































.ciic-tables-operate {
  .el-button-group .el-button--primary:first-child{
    width: 80px;
  }
  .singleButton {
    width: 80px;
  }
}


/*.ciic-tables-operate .el-dropdown .el-dropdown__caret-button::before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  display: block;*/
/*  width: 0px;*/
/*  top: 5px;*/
/*  bottom: 5px;*/
/*  left: 0;*/
/*  background: rgba(255,255,255,.5);*/
/*}*/
