


























































































































































































.borrow{
  display: flex;
}
.borrowLeftHead{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.borrowLeftTitle p{
  color:#666666;
  font-size: 14px;
  font-weight: 600;
}
.borrowLeftTypes{
  font-size: 12px;
  color:#999999;
  p{
    margin: 0;
  }
  i{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius:2px;
  }
}
.borrowLists{
  padding:0 20px;
  height: 350px;
  overflow-y: auto;
  .borrowList{
    background: #ffffff;
    width: 300px;
    margin-bottom: 20px;
    .borrowListName{
      padding-left:10px;
      font-size: 12px;
      color:#ffffff;
      font-size: 12px;
      font-weight: 600;
      line-height: 32px;
      position: relative;
      border-radius: 4px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .borrowListData{
      padding: 10px;
      box-shadow:0px 1px 4px 0px rgba(32,64,86,0.17);
      border-radius: 4px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .borrowListData p{
      color:#999;
      font-size: 12px;
      line-height: 2;
      span{
        color:#333;
      }
    }
  }
}
.ordinary{
  background: #7EB9E3;
}
.special{
  background: #E181CB;
}
.borrowRight{
  width: 400px;
  margin-left: 20px;
  p{
    text-align: center;
    color:#666666;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}

