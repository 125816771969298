


































































































































.main{
}
.search-head{
}
.card{
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  margin-top: 5px;
  padding: 18px 20px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 998;
  background: #fff;
}
.card-width {
  width: 470px;
}
.hidden-content{
  margin-bottom: 10px;
}
.el-input__icon{
  font-size: 20px;
  cursor: pointer;
}
.fade-enter-active, .fade-leave-active {
  transition: all .4s;
}
.fade-enter, .fade-leave-active {
  transform: scale(0);
  transform-origin: right top;
  opacity: 0;
}
